import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FiExternalLink } from "react-icons/fi"
import {
  FaTwitter,
  FaGithub,
  FaFacebook,
  FaHeart,
  FaArrowAltCircleUp,
  FaArrowAltCircleDown,
} from "react-icons/fa"
import { HashLoader } from "react-spinners"
import { FiRefreshCw } from "react-icons/fi"
const backend = require("../helpers/devsurvival.api.js")
export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [showdevs, setShowdevs] = useState([])
  const [loading, setLoading] = useState(false)
  const alpha = 0.6
  useEffect(() => {
    // getting dynamically generated showcases that were created after the build
    setLoading(true)
    backend.getShowcases().then(shows => {
      setShowdevs(shows)
      setTimeout(() => setLoading(false), 250)
    })
  }, [])
  const refresh = async e => {
    setLoading(true)
    try {
      const shows = await backend.getShowcases()
      setShowdevs(shows)
    } catch (err) {}

    setTimeout(() => setLoading(false), 250)
  }
  const ShowDev = ({
    _id,
    title,
    name,
    description,
    demoLink,
    personalWeb,
    twitter,
    github,
    facebook,
    technologies,
    hearts,
    upVotes,
    downVotes,
  }) => {
    const addHeart = async (id, currentHearts) => {
      const updated = await backend.updateShowcase(id, {
        hearts: currentHearts + 1,
      })
      setShowdevs(
        showdevs.reduce((acc, next) => {
          if (next._id === updated._id) {
            return [...acc, updated]
          }
          return [...acc, next]
        }, [])
      )
    }
    const upVote = async (id, currentUpVotes) => {
      const updated = await backend.updateShowcase(id, {
        upVotes: currentUpVotes + 1,
      })
      setShowdevs(
        showdevs.reduce((acc, next) => {
          if (next._id === updated._id) {
            return [...acc, updated]
          }
          return [...acc, next]
        }, [])
      )
    }
    const downVote = async (id, currentDownVotes) => {
      const updated = await backend.updateShowcase(id, {
        downVotes: currentDownVotes + 1,
      })
      setShowdevs(
        showdevs.reduce((acc, next) => {
          if (next._id === updated._id) {
            return [...acc, updated]
          }
          return [...acc, next]
        }, [])
      )
    }
    return (
      <div className="flex-row-start showdev-item">
        <div className="showdev-item-bar">
          <span>
            <span>{upVotes}</span>
            <FaArrowAltCircleUp
              onClick={() => upVote(_id, upVotes)}
              size={16}
            />
          </span>
          <span>
            <span>{hearts}</span>
            <FaHeart
              onClick={() => addHeart(_id, hearts)}
              size={16}
              color="#B73831"
            />
          </span>
          <span>
            <span>{downVotes}</span>
            <FaArrowAltCircleDown
              onClick={() => downVote(_id, downVotes)}
              size={16}
            />
          </span>
        </div>
        <div className="showdev-item-content">
          <h4>{title}</h4>

          <p>
            <b>Creator:</b> {name}
          </p>
          <p>
            <b>Description:</b> {description}
          </p>
          <p>
            <b>Technologies:</b>
            {technologies.map((t, i) => (
              <span key={i}> {t},</span>
            ))}{" "}
          </p>
          <p>
            {demoLink && (
              <a href={demoLink} target="_blank" rel="noopener noreferrer">
                Demo
              </a>
            )}
          </p>
          {personalWeb && (
            <a href={personalWeb} target="_blank" rel="noopener noreferrer">
              <FiExternalLink size={16} />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer">
              <FaTwitter color="#4AA1EC" size={16} />
            </a>
          )}
          {github && (
            <a href={github} target="_blank" rel="noopener noreferrer">
              <FaGithub size={16} color="#26292E" />
            </a>
          )}
          {facebook && (
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <FaFacebook color="#415A94" size={16} />
            </a>
          )}
        </div>
      </div>
    )
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="ShowDevs" />
      <h4>🎉🔥 Developer ShowDev 🔥🎉</h4>
      <p>
        {" "}
        <Link to="/showcase-learn-more/">Submit your project</Link>
      </p>
      <button onClick={refresh}>
        <FiRefreshCw /> refresh
      </button>
      {loading ? (
        <HashLoader />
      ) : (
        <div>
          {showdevs.length ? (
            <div className="showdev-container">
              {showdevs
                .sort((a, b) => {
                  const aC = a.upVotes - alpha * a.downVotes
                  const bC = b.upVotes - alpha * b.downVotes
                  if (aC === bC) {
                    return a.hearts > b.hearts ? -1 : 1
                  }
                  return aC > bC ? -1 : 1
                })
                .map(s => (
                  <ShowDev key={s._id} {...s} />
                ))}
            </div>
          ) : (
            <div>
              <p> There are no showcase yet</p>
              <p>
                <Link to="/showcase-learn-more/">Be the first</Link>
              </p>
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
